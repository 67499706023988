.mobile-campaign__photos {
    display: block;
    width: 100%;
    margin: 0 auto;
    border-radius: 2.5em;
}

.mobile-campaign__photo {
    display: block;
    width: var(--size-swiper-w);
    height: var(--size-swiper-h);
    position: relative;
}

.mobile-campaign__photo-container {
    display: block;
    width: var(--size-swiper-w);
    height: var(--size-swiper-h);
    position: relative;
    border-radius: 2.5em;
}

.mobile-campaign__description {
    font-family: 'Poppins-Regular';
    display: block;
    width: fit-content;
    margin: 0 auto;
    color: var(--color--gray-600);
    font-size: var(--font-p-body-medium-mobile);
    font-weight: 600;
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-align: justify;
    text-align-last: center;
}

.mobile-campaign__progress {
    width: var(--size-progress-bar-w);
    height: var(--size-progress-bar-h);
    border-radius: 1.5em;
}








.mobile-campaign__photos{
    display: block;
    width: 100%;
    margin: 0 auto;

    border-radius: 2.5em;
}
.mobile-campaign__photo{
    display: block;
    width: var(--size-swiper-w);
    height: var(--size-swiper-h);
    position: relative;
}

.mobile-campaign__photo-container{
    display: block;
    width: var(--size-swiper-w);
    height: var(--size-swiper-h);
    position: relative;
    border-radius: 2.5em;
    overflow: hidden;
}

.mobile-campaign__photo-front{
    display: block;
    width: var(--size-swiper-w);
    height: var(--size-swiper-h);
    position: absolute;
    top: 0px;
}
.mobile-campaign__photo-back{
    display: block;
    width: var(--size-swiper-w);
    height: var(--size-swiper-h);
    position: absolute;
    top: 0px;
    filter: blur(7px) brightness(65%);
}

.mobile-campaign__photo-container.container--vertical .mobile-campaign__photo-front img.photo{
    display: block;
    width: 100%;
    height: var(--size-swiper-h);
    object-fit: contain;
}
.mobile-campaign__photo-container.container--vertical .mobile-campaign__photo-back  img.photo{
    display: block;
    width: 100%;
    height: var(--size-swiper-h);
    object-fit: cover;
}

.mobile-campaign__photo-container.container--horizontal .mobile-campaign__photo-front img.photo{
    display: block;
    width: 100%;
    height: var(--size-swiper-h);
    object-fit: cover;
}
.mobile-campaign__photo-container.container--horizontal .mobile-campaign__photo-back  img.photo{
    display: block;
    width: 100%;
    height: var(--size-swiper-h);
    object-fit: contain;
}

.device.device--mobile .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 4em;
    left: 0;
    width: 100%;
}





.mobile-campaign__category {
    color: var(--color--lcgreen-700);
    font-family: 'Poppins-Regular';
    font-size: var(--font-p-body-medium-mobile);
    padding-bottom: 0.5em;
}
.mobile-campaign__donated {
    color: var(--color--lcgreen-700);
    font-family: 'Poppins-Regular';
    font-size: var(--font-p-body-medium-mobile);
    padding-bottom: 0.5em;
}
.mobile-campaign__missing{
    color: var(--color--lcdred-700);
    font-family: 'Poppins-Semibold';
    font-size: var(--font-p-body-medium-mobile);
    padding-bottom: 0.5em;
}
.mobile-campaign__icons-group{

}
.icons-group img{
    width: 40px;
    margin-right: 10px;
}
.icons-group.icon--green{
    padding-bottom: 0.5em;
    color: var(--color--lcgreen-700);
    font-family: 'Poppins-Regular';
    font-size: var(--font-p-body-medium-mobile);
}
.icons-group.icon--red{
    padding-bottom: 0.5em;
    color: var(--color--lcdred-700);
    font-family: 'Poppins-Regular';
    font-size: var(--font-p-body-medium-mobile);
}




.mobile-campaign__description{
    font-family: 'Poppins-Semibold';
    color: var(--color--green-500);
    padding-top: 3em;
    padding-bottom: 3em;
    padding-left: 0em;
    padding-right: 0em;
}
.mobile-campaign__description .description__title{
    font-size: var(--font-p-body-large-mobile);
    color: var(--color--lcgreen-700);
    text-align: center;
    padding-bottom: 1em;
}
.mobile-campaign__description .description__texts{
    font-size: var(--font-p-body-medium-mobile);
    color:var(--color--gray-500);
    font-family: 'Poppins-Regular';
}
.mobile-campaign__description .description__more{}
