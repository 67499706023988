.campaigns-list {
    padding-top: 2em;
    font-size: 18px;
    font-family: 'Poppins-Regular';
    color: #79a395;
    .campaing-card {
      display: block;
      width: 352px;
      box-sizing: border-box;
      padding-right: 1em;
      padding-bottom: 1em;
      &:nth-child(4n + 4) {
        padding-left: 0em;
      }
  
      &:nth-child(5n + 5) {
        padding-left: 0em;
      }
  
      &:first-child {
        padding-left: 0em;
      }
    }
    display: block;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  