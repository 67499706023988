.device.device--mobile {
    
    --size-font-scale-factor: 1.5;

    /* Mobile */
    --h1-heading-mobile: 3.2rem; /* 32px */
    --h2-title-mobile: 2.8rem; /* 28px */
    --h3-subtitle-mobile: 2.4rem; /* 24px */
    --h4-section-header-mobile: 2rem; /* 20px */
    --p-body-large-mobile: 1.6rem; /* 16px */
    --p-body-medium-mobile: 1.4rem; /* 14px */
    --p-body-small-mobile: 1.2rem; /* 12px */

    --font-h1-heading-mobile: calc(var(--size-font-scale-factor) * var(--h1-heading-mobile)); /* 32px */
    --font-h2-title-mobile: calc(var(--size-font-scale-factor) * var(--h2-title-mobile)); /* 28px */
    --font-h3-subtitle-mobile: calc(var(--size-font-scale-factor) * var(--h3-subtitle-mobile)); /* 24px */
    --font-h4-section-header-mobile: calc(var(--size-font-scale-factor) * var(--h4-section-header-mobile)); /* 20px */
    --font-p-body-large-mobile: calc(var(--size-font-scale-factor) * var(--p-body-large-mobile)); /* 16px */
    --font-p-body-medium-mobile: calc(var(--size-font-scale-factor) * var(--p-body-medium-mobile)); /* 14px */
    --font-p-body-small-mobile: calc(var(--size-font-scale-factor) * var(--p-body-small-mobile)); /* 12px */
}


.device.device--tablet {
    /* Tablet */
    --font-h1-heading-tablet: 4rem; /* 40px */
    --font-h2-title-tablet: 3.4rem; /* 34px */
    --font-h3-subtitle-tablet: 2.8rem; /* 28px */
    --font-h4-section-header-tablet: 2.4rem; /* 24px */
    --font-p-body-large-tablet: 2rem; /* 20px */
    --font-p-body-medium-tablet: 1.6rem; /* 16px */
    --font-p-body-small-tablet: 1.4rem; /* 14px */
}

.device.device--laptop {
    /* Laptop */
    --font-h1-heading-laptop: 4.8rem; /* 48px */
    --font-h2-title-laptop: 4.4rem; /* 44px */
    --font-h3-subtitle-laptop: 4rem; /* 40px */
    --font-h4-section-header-laptop: 3.4rem; /* 34px */
    --font-p-body-large-laptop: 2.8rem; /* 28px */
    --font-p-body-medium-laptop: 2.4rem; /* 24px */
    --font-p-body-small-laptop: 2rem; /* 20px */
}