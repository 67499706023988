@path-fonts: '/assets/fonts';

.font-face-generator(@font-name, @font-weight) {
  @font-face {
    font-family: '@{font-name}-@{font-weight}';
    src: url('@{path-fonts}') format('enbedded-opentype'), url('@{path-fonts}/@{font-name}/@{font-name}-@{font-weight}.ttf') format('truetype'),
      url('@{path-fonts}/@{font-name}/@{font-name}-@{font-weight}.woff') format('woff'),
      url('@{path-fonts}/@{font-name}/@{font-name}-@{font-weight}.svg#@{font-name}-@{font-weight}') format('svg');
  }
}

.font-family-generator(@font-family-name) {
  .font-face-generator(@font-family-name, 'Black');
  .font-face-generator(@font-family-name, 'BlackItalic');
  .font-face-generator(@font-family-name, 'Bold');
  .font-face-generator(@font-family-name, 'BoldItalic');
  .font-face-generator(@font-family-name, 'ExtraBold');
  .font-face-generator(@font-family-name, 'ExtraBoldItalic');
  .font-face-generator(@font-family-name, 'ExtraLight');
  .font-face-generator(@font-family-name, 'ExtraLightItalic');
  .font-face-generator(@font-family-name, 'Italic');
  .font-face-generator(@font-family-name, 'Light');
  .font-face-generator(@font-family-name, 'LightItalic');
  .font-face-generator(@font-family-name, 'Medium');
  .font-face-generator(@font-family-name, 'MediumItalic');
  .font-face-generator(@font-family-name, 'Regular');
  .font-face-generator(@font-family-name, 'SemiBold');
  .font-face-generator(@font-family-name, 'SemiBoldItalic');
  .font-face-generator(@font-family-name, 'Thin');
  .font-face-generator(@font-family-name, 'ThinItalic');
}
