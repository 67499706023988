.w-100p {
    width: 100%;
}

.page--container {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding-left: 3em;
    padding-right: 3em;
}

.background-color--white {
    background-color: var(--color--white);
}

.background-color--green {
    background-color: var(--color--gray-200);
}

.background-color--red {
    background-color: var(--color--red-200);
}

.background-color--gray {
    background-color: var(--color--gray-100);
}

.font-color--red {
    color: var(--color--red-200);
}

.font-color--green {
    color: var(--color--green-500);
}
