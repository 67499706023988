@import './mobile-variables-colors.less';
@import './mobile-variables-fonts.less';
@import './mobile-variables-components.less';

@import './mobile-titles.less';
@import './mobile-view-campaign.less';
@import './mobile-view-checkout.less';
@import './mobile-utilities-classes.less';





.mobile-checkout__section .section__container{
    display: block;
    width: 100%;
    margin: 0 auto;
    padding-left: 3em;
    padding-right: 3em;

    padding-top: 0em;
    padding-bottom: 0em;
}