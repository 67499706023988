/**
 * Typography Styles
 *
 * This section defines typography styles for titles, subtitles, and paragraphs.
 */


/* Base Titles for Mobile */

/* Title for Page / View */
.mobile__h1_title{
    font-family: 'Poppins-SemiBold';
    display: block;
    width: fit-content;
    margin: 0 auto;
    padding-top: 1.5em;
    padding-bottom: 0.5em;
}
.mobile__h1_subtitle{
    font-family: 'Poppins-Regular';
    display: block;
    width: fit-content;
    margin: 0 auto;
    font-weight: 600;
    font-size: var(--font-p-body-medium-mobile);
    padding-bottom: 2em;
}

/* Title for Section */
.mobile__h2_title{}
.mobile__h2_subtitle{}

/* Title for Components / Groups */
.mobile__h3_title{}
.mobile__h3_subtitle{}


/* Texts for Paragraphs */

.mobile__p-small {
    display: block;
    margin: 0 auto;
    width: calc(100% - 1em);
    padding: 1em 0;
    text-align: center;
    color: var(--color--lcgreen-700);
    font-family: 'Poppins-Regular';
    font-size: var(--font-p-body-small-mobile);
    letter-spacing: 1px;
}
.mobile__p-medium{
    font-size: var(--font-p-body-medium-mobile);
    color: var(--color--gray-500);
    font-family: 'Poppins-Regular';
    text-align: start;
    font-weight: 100;
}
.mobile__p-large{
    font-size: var(--font-p-body-large-mobile);
    color: var(--color--gray-500);
    font-family: 'Poppins-Regular';
    text-align: start;
    font-weight: 100;
}

.mobile__p-small b,.mobile__p-medium b,.mobile__p-large b{
    font-family: 'Poppins-SemiBold' !important;
    font-weight: 100;
}


/* Title Styles */
.mobile__title {
    font-family: 'Poppins-SemiBold';
    display: block;
    width: fit-content;
    margin: 0 auto;
    padding-top: 1em;
}

/* Title Variants */
.mobile__title--red {
    color: var(--color--red-500);
}

.mobile__title--green {
    color: var(--color--green-500);
}
.mobile__title--darkgreen {
    color: var(--color--lcgreen-700);
}

.mobile__title--gray {
    color: var(--color--gray-500);
}

/* Subtitle Styles */
.mobile__subtitle {
    font-family: 'Poppins-Regular';
    display: block;
    width: fit-content;
    margin: 0 auto;
    font-weight: 600;
    font-size: var(--font-p-body-medium-mobile);
    padding-bottom: 2em;
}

/* Additional Title Style */
.mobile__title2 {
    font-size: var(--font-p-body-large-mobile);
    text-align: center;
    padding-bottom: 1em;
}

/* Font Size for Medium Paragraphs */
.font-p-body-medium {
    font-size: var(--font-p-body-medium-mobile);
}