@import './colors.less';

input.input-default {
}
input.input-failed {
  border: 1px solid @color-red !important;
  color: @color-red !important;
}

span.input-failed {
  font-family: 'Poppins-Regular';
  color: @color-red !important;
  font-size: 0.9em !important;
  text-align: right;
  display: block;
  width: 100%;
  position: relative;
  padding: 0.5em 0em;
}
