.device.device--mobile {
    --size-swiper-w: 100%;
    --size-swiper-h: 500px;

    --size-btn-shadow-r: 12px;
   
 
    --size-progress-bar-h: 25px;
}


 .mobile-button{
    display: block;

    margin: 0 auto;
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    padding: 0.75em 2.5em;
    border-radius: 1.25em;

    border: none;
    outline: none;

    color: white;
    font-family: 'Poppins-SemiBold';
    font-size: var(--font-h4-section-header-mobile);
    font-weight: bold;
    letter-spacing: 1px;
}
.button--solid{

}
.button--solid.button--red{
    border-color: var(--color--active-red);
    outline-color: var(--color--active-red);
    background-color: var(--color--active-red);
}
.button--solid.button--green{
    color: var(--color--green-500);
    border-color: var(--color--lcgreen-50);
    outline-color: var(--color--lcgreen-50);
    background-color: var(--color--lcgreen-50);
}
.button--outline.button--red{
    border-color: var(--color--active-red);
    outline-color: var(--color--active-red);
    background-color: white;
    color: var(--color--active-red);
}
.button--outline.button--green{
    color: var(--color--green-500);
    border-color: var(--color--lcgreen-50);
    outline-color: var(--color--lcgreen-50);
    background-color: white;
}
.shadow--green{
    -webkit-box-shadow: 0px 0px var(--size-btn-shadow-r) 0px var(--color--active-green);
    box-shadow: 0px 0px var(--size-btn-shadow-r) 0px var(--color--active-green);
}
.shadow--red{
    -webkit-box-shadow: 0px 0px var(--size-btn-shadow-r) 0px var(--color--active-red);
    box-shadow: 0px 0px var(--size-btn-shadow-r) 0px var(--color--active-red);
}

.shadow--gray{
    -webkit-box-shadow: 0px 0px var(--size-btn-shadow-r) 0px var(--color--gray-300);
    box-shadow: 0px 0px var(--size-btn-shadow-r) 0px var(--color--gray-300);
}

.mobile__currency-btn{
    width: 100%;
    background-color: white;

    color: var( --color--green-500);
    border: 3px solid var( --color--green-500);
    border-radius: 3em;

    margin: 0px;
    margin-right: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
}
 .buttons_swiper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-top: 0.5em;
    margin-bottom: 1em;
 }
 .buttons_swiper img{
    padding-left: 10px;
    padding-right: 10px;
 }


.mobile__currency-btn{
    font-size: var(--font-p-body-large-mobile);
}
.mobile__currency-btn.selected{
    color: white;
    background-color: var( --color--green-500);
}

.buttons_swiper .mobile__currency-btn{
    width: calc(100% - 30px);
    font-size: var(--font-p-body-large-mobile);
    padding-left: 10px;
    padding-right: 10px;
}






.mobile__progress-bar{
    display: block;
    width: 100%;
    background-color: var(--color--gray-100);
    height: var(--size-progress-bar-h);

    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 2em;

}
.mobile__progress-bar .progress-bar__container{
    display: block;
    width: 100%;
    background-color: var(--color--gray-100);
    height: var(--size-progress-bar-h);
    border-radius: 2em;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;

}
.mobile__progress-bar .progress-bar__level{
    display: block;
    width: 100%;
    background-color: var(--color--green-500);
    height: var(--size-progress-bar-h);
    border-radius: 2em;
}

.mobile__input-text{
    display: block;
    width: 100%;
    padding: 1em;
    padding-left: 0px;
    padding-right: 0px;
    background-color: transparent;
    font-size: var(--font-p-body-medium-mobile);
    color:  var(--color--lcgreen-700);
    border-left: none;
    border-right: none;
    border-top: none;
    letter-spacing: 1px;
    border-bottom: 1px solid #4fb99b8d;
}