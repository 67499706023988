:root {
    font-size: 62.5%;

    /* Black & White */
    --color--black: #1b2626;
    --color--white: #ffffff;

    /* Grays (Funs) */
    --color--gray-100: #eff7f6;
    --color--gray-200: #e2eded;
    --color--gray-300: #b6cac9;
    --color--gray-400: #95a5a5;
    --color--gray-500: #5d6767;
    --color--gray-600: #2d413e;

    /* Brand Colors */
    --color--red-200: #faebeb; 
    --color--green-200: #d5ebe2;
    --color--red-500: #ed3f4c;
    --color--green-500: #4fb99b;
    --color--gradient-start: #d6eada;
    --color--gradient-end: #b1dbce;
    --color--yellow: #ffe65a;

    /* Boolean & Active Colors */
    --color--false: #d96262;
    --color--success: #8dd9a6;
    --color--active-red: #ff6668;
    --color--active-green: #73d1b9;

    /* Light Mode */
    --color--lcred-50: #feedee;
    --color--lcgreen-50: #ebf5ed;
    --color--lcdred-700: #b74a54;
    --color--lcgreen-700: #4e887f;


    --color--active-red:#FF5252;
    --color--active-green:#5ACCAC;

    /* Typography Poppins */
    --font-family: 'Poppins', sans-serif;
}